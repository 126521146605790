import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "paginator",
      "style": {
        "position": "relative"
      }
    }}>{`Paginator`}</h1>
    <p>{`Usado para navegar entre várias páginas sempre que houver muitos resultados para mostrar de uma só vez, ou quando algo levar muito tempo para carregar/renderizar todos os itens. É normalmente usado em uma tabela ou para exibir resultados da pesquisa.`}</p>
    <h3 {...{
      "id": "exemplo",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplo`}</h3>
    <Demo src='pages/components/paginator/Paginator' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      